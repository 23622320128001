import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";
import { selectCourseById, selectCoursesByItemList } from "../courseSlice";
import { Typography } from "@mui/material";
import CourseCard from "../components/CourseCard";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CheckIcon from "@mui/icons-material/Check";
import { LoremIpsum } from "lorem-ipsum";
import { getPersonalizeRelatedRecommendations } from "../util";
import { sendPersonalizeEvent, sendPutProjectEvent } from "../util";
import Paper from "@mui/material/Paper";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import MLink from "@mui/material/Link";
import {
  addCourse,
  finishCourse,
  selectIsCourseAdded,
  selectIsCourseFinished,
} from "../myCoursesSlice";
import { useSnackbar } from 'notistack';


const lorem = new LoremIpsum({
    format: 'html',
  sentencesPerParagraph: {
    max: 8,
    min: 4,
  },
  wordsPerSentence: {
    max: 16,
    min: 4,
  },
});

const Course = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { courseId } = params;
  const [relatedCourses, setRelatedCourses] = React.useState(null);
  const [coureseDetails] = React.useState(lorem.generateParagraphs(4));
  const isCourseAdded = useSelector(selectIsCourseAdded);
  const isCourseFinished = useSelector(selectIsCourseFinished);

  const courseById = useSelector(selectCourseById);
  const coursesByItemList = useSelector(selectCoursesByItemList);
  React.useEffect(
    getPersonalizeRelatedRecommendations(
      courseId,
      relatedCourses,
      setRelatedCourses
    ),
    []
  );
  React.useEffect(sendPersonalizeEvent(courseId, "CourseViewed"), [courseId]);
  const handleAddCourse = React.useCallback(() => {
    enqueueSnackbar('Added Course!', { variant: 'default' });
    dispatch(addCourse(course.ITEM_ID));
    sendPutProjectEvent("ABAppProject", "addCourse", 1);
    sendPersonalizeEvent(courseId, "CourseAdded");
  }, [courseId]);
  const handleFinishCourse = React.useCallback(() => {
    enqueueSnackbar('Finished Course!', { variant: 'default' });
    dispatch(finishCourse(course.ITEM_ID));
    sendPutProjectEvent("ABAppProject", "finishCourse", 1);
    sendPersonalizeEvent(courseId, "CourseFinished");
  }, [courseId]);

  let course = courseById(courseId);

  return course ? (
    <Box sx={{ flexGrow: 1, padding: 2 }}>
      <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: "8px" }}>
        <MLink underline="hover" color="inherit" component={Link} to="/courses">
          Back to Search
        </MLink>
        <Typography color="text.primary">{course.TITLE}</Typography>
      </Breadcrumbs>
      <Card elevation={4}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5" noWrap component="div">
                {course.TITLE}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  height: 300,
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                  placeContent: "center",
                  fontSize: "3rem",
                  overflow: "hidden",
                  position: "relative",
                  color: "#00000073",
                  lineHeight: 1,
                  background: `${course.COLOR}4d`,
                  fontWeight: course.FONT_WEIGHT,
                  fontFamily: course.FONT_FAMILY,
                  textTransform: course.TEXT_TRANSFORM,
                  fontStyle: course.FONT_STYLE,
                  "&::before": {
                    content: '""',
                    position: "absolute",
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 0,
                    background:
                      "radial-gradient(farthest-side,white 30%,transparent 82%) 0 0/ 1px 4px",
                    webkitMask: "linear-gradient(to left,#000,transparent)",
                    mask: "linear-gradient(to left,#000,transparent)",
                  },
                }}
              >
                {course.TITLE}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Stack
                spacing={2}
                sx={{
                  flexDirection: { xs: "column", md: "row" },
                  justifyContent: { xs: "inherit", md: "space-between" },
                  alignItems: { xs: "left", md: "center" },
                }}
              >
                <Stack direction="column" spacing={0}>
                  <Typography
                    noWrap={true}
                    variant="overline"
                    component="div"
                    sx={{ lineHeight: "inherit" }}
                  >
                    {course.CATEGORY}
                  </Typography>
                  <Typography noWrap={true} variant="subtitle2" component="div">
                    {course.SUBCATEGORY}
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={2}>
                  <Button
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={handleAddCourse}
                    variant="contained"
                    disabled={isCourseAdded(course.ITEM_ID)}
                  >
                    Add Course
                  </Button>
                  <Button
                    startIcon={<CheckCircleOutlineIcon />}
                    onClick={handleFinishCourse}
                    variant="outlined"
                    disabled={isCourseFinished(course.ITEM_ID)}
                  >
                    Mark Finished
                  </Button>
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">About this course:</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body">
                {coureseDetails}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4" noWrap component="div">
                Related Courses
              </Typography>
            </Grid>
            {relatedCourses &&
              coursesByItemList(relatedCourses).map((relatedCourse) => {
                return (
                  <Grid key={relatedCourse.ITEM_ID} item xs={12} md={4} lg={3}>
                    <CourseCard course={relatedCourse} variant="outlined" />
                  </Grid>
                );
              })}
          </Grid>
        </CardContent>
      </Card>
    </Box>
  ) : null;
};
export default Course;
