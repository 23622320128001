import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Routes, Route, useLocation } from "react-router-dom";

import "./App.css";
import { withAuthenticator, AmplifySignOut } from "@aws-amplify/ui-react";
import Amplify, { Auth } from "aws-amplify";
import aws_exports from "./aws-exports";

import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./theme";
import { ColorModeContext } from "./context";

import Layout from "./Layout";
import Home from "./pages/Home";
import Courses from "./pages/Courses";
import Course from "./pages/Course";
import Categories from "./pages/Categories";
import About from "./pages/About";
import NotFound from "./pages/NotFound";

import {
  useEvidenltyEffect,
  useRUMInstallEffect,
  useRUMUpdatePageEffect,
} from "./util";

import {
  selectIsFreeTextSearchOn,
  updateIsFreeTextSearchOn,
} from "./evidentlySlice";
import ScrollToTop from "./components/ScrollToTop";
import { SnackbarProvider } from "notistack";

Amplify.configure(aws_exports);

const App = () => {
  const isFreeTextSearchOn = useSelector(selectIsFreeTextSearchOn);
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(useRUMInstallEffect, []);

  useEffect(useRUMUpdatePageEffect(location.pathname), [location]);

  useEffect(
    useEvidenltyEffect(
      "ABAppProject",
      "FreetextSearch",
      isFreeTextSearchOn,
      (value) => dispatch(updateIsFreeTextSearchOn(value))
    ),
    []
  );

  const [mode, setMode] = React.useState(sessionStorage.getItem('theme') || 'green');
  const colorMode = React.useMemo(
    () => ({
      changeColorMode: (color) => {
        setMode(color);
        sessionStorage.setItem('theme', color);
      },
    }),
    []
  );

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme(mode)}>
        <ScrollToTop>
          <SnackbarProvider maxSnack={3}>
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route path="" element={<Home />} />
                <Route path="courses" element={<Courses />} />
                <Route path="courses/:courseId" element={<Course />} />
                <Route path="categories" element={<Categories />} />
                <Route path="about" element={<About />} />
                <Route path="*" element={<NotFound />} />
              </Route>
            </Routes>
          </SnackbarProvider>
        </ScrollToTop>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

// export default withAuthenticator(App);
export default App;
