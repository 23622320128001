import { createSlice } from '@reduxjs/toolkit'
import { courseData } from "./data/courseData"

export const courseSlice = createSlice({
  name: 'course',
  initialState: {
    value: courseData,
    categories: [
        'Design',
        'Development',
        'Finance & Accounting',
        'Leadership & Management',
        'Marketing',
        'Lifestyle',
    ]
  },
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1
    },
    decrement: (state) => {
      state.value -= 1
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { increment, decrement, incrementByAmount } = courseSlice.actions

export const selectCourses = (state) => state.course.value;
export const selectCourseById = (state) => {
    return (courseId) => state.course.value.find(course => course.ITEM_ID === Number(courseId));
};
export const selectCoursesByCategory = (state) => {
    return (category) => state.course.value.filter(course => course.CATEGORY === category);
};
export const selectCoursesByItemList = (state) => {
    return (courseIds) => {
        const ids = courseIds.map(item => Number(item.itemId));
        return state.course.value.filter(course => ids.includes(course.ITEM_ID))
    };
};

export const selectCategories = (state) => state.course.categories;


export default courseSlice.reducer