import * as React from "react";
import { useSelector } from "react-redux";
import { selectCourses, selectCategories } from "../courseSlice";
import { Stack, Typography } from "@mui/material";
import CourseCard from "../components/CourseCard";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FilterListIcon from "@mui/icons-material/FilterList";
import { selectIsFreeTextSearchOn } from "../evidentlySlice";
import { browserDetect } from "../util";

const Courses = () => {
  const courses = useSelector(selectCourses);
  const categories = useSelector(selectCategories);
  const isFreeTextSearchOn = useSelector(selectIsFreeTextSearchOn);
  const [categoryFilter, setCategoryFilter] = React.useState("All");
  const [titleFilter, setTitleFilter] = React.useState("");

  const handleCategoryFilterChange = (event) => {
    setCategoryFilter(event.target.value);
  };

  const handleTitleFilterChange = (event) => {
    setTitleFilter(event.target.value);
  };

  return (
    <Box sx={{ flexGrow: 1, padding: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4" noWrap component="div">
            Courses
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Stack direction="column" spacing={2}>
            <Grid container direction="row" alignItems="center" spacing={1}>
              <Grid item>
                <FilterListIcon fontSize="small" />
              </Grid>
              <Grid item>
                <Typography variant="overline" noWrap component="div">
                  Filters
                </Typography>
              </Grid>
            </Grid>
            <FormControl>
              <FormLabel id="buttons-group-label">Select Category</FormLabel>
              <RadioGroup
                row
                aria-labelledby="buttons-group-label"
                name="row-radio-buttons-group"
                value={categoryFilter}
                onChange={handleCategoryFilterChange}
              >
                <FormControlLabel value="All" control={<Radio />} label="All" />
                {categories.map((category) => (
                  <FormControlLabel
                    key={category}
                    value={category}
                    control={<Radio />}
                    label={category}
                  />
                ))}
              </RadioGroup>
            </FormControl>
            <TextField
              sx={{ display: isFreeTextSearchOn ? "inherit" : "none" }}
              fullWidth
              label="Search Course Title..."
              variant="outlined"
              value={titleFilter}
              onChange={handleTitleFilterChange}
            />
            <Divider variant="middle" />
          </Stack>
        </Grid>
        {courses
          .filter(
            (course) =>
              categoryFilter === "All" || course.CATEGORY === categoryFilter
          )
          .filter(
            (course) =>
              titleFilter === "" ||
              course.TITLE.toLowerCase().includes(titleFilter.toLowerCase())
          )
          .map((course) => {
            return (
              <Grid key={course.ITEM_ID} item xs={12} md={4} lg={3}>
                <CourseCard course={course} />
              </Grid>
            );
          })}
      </Grid>
    </Box>
  );
};
export default Courses;
