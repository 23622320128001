import * as React from "react";
import { useSelector } from "react-redux";
import { Typography } from "@mui/material";
import { selectCoursesByItemList } from "../courseSlice";
import CourseCard from "../components/CourseCard";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import { LoremIpsum } from "lorem-ipsum";
import { getPersonalizeRecommendations } from "../util";
import { Block } from "@mui/icons-material";
import Button from "@mui/material/Button";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import { Link } from "react-router-dom";

const Home = () => {
  const coursesByItemList = useSelector(selectCoursesByItemList);
  const [recommendedCourses, setRecommendedCourses] = React.useState(null);
  React.useEffect(
    getPersonalizeRecommendations(recommendedCourses, setRecommendedCourses),
    []
  );

  return (
    <Box sx={{ flexGrow: 1, padding: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack
            sx={{
              height: "350px",
              overflow: "auto",
              position: "relative",
              display: "flex",
              alignItems: "center",
              alignSelf: "center",
              justifyContent: "center",
              "&::before": {
                content: '""',
                position: "absolute",
                background: `url('https://upload.wikimedia.org/wikipedia/commons/thumb/2/2f/Colorful_books.jpg/1600px-Colorful_books.jpg?20190729185806')`,
                backgroundPositionY: "center",
                backgroundSize: "cover",
                filter: "blur(4px)",
                webkitFilter: "blur(4px)",
                height: "350px",
                width: "100%",
                zIndex: -1,
              },
            }}
          >
            <Stack
              sx={{
                background: "rgba(0,0,0,.5)",
                color: "white",
                borderRadius: "4px",
                margin: "8px",
                padding: "12px 24px",
              }}
            >
              <Typography
                variant="h2"
                sx={{
                  fontWeight: "bold",
                }}
              >
                Discover Your Course
              </Typography>
              <Button
                component={Link}
                to="/courses"
                size="large"
                variant="contained"
                color="primary"
                sx={{
                    alignSelf: 'center'
                }}
              >
                Find Now
              </Button>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="h6">A global learning solution</Typography>
          <Typography variant="body">Our enterprise-ready features are designed to help you scale learning to all employees across your organization.</Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="h6">Comprehensive content</Typography>
          <Typography variant="body">We offer fresh courses on emerging topics that keep your employees ahead of the curve.</Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="h6">Native-language learning</Typography>
          <Typography variant="body">English, Brazilian Portuguese, French, German, Spanish, Italian, Polish, Turkish, Indonesian, Mandarin, Arabic, Russian, Korean, and Japanese</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" noWrap component="div">
            Suggested Courses
          </Typography>
        </Grid>
        {recommendedCourses &&
          coursesByItemList(recommendedCourses).map((course) => {
            return (
              <Grid key={course.ITEM_ID} item xs={12} md={4} lg={3}>
                <CourseCard course={course} />
              </Grid>
            );
          })}
      </Grid>
    </Box>
  );
};
export default Home;
