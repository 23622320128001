import { createSlice } from "@reduxjs/toolkit";

export const evidentlySlice = createSlice({
  name: "evidently",
  initialState: {
    isFreeTextSearchOn: null,
  },
  reducers: {
    updateIsFreeTextSearchOn: (state, action) => {
      state.isFreeTextSearchOn = action.payload;
    },
  },
});

export const { updateIsFreeTextSearchOn } = evidentlySlice.actions;

export const selectIsFreeTextSearchOn = (state) =>
  state.evidently?.isFreeTextSearchOn?.boolValue === undefined
    ? null
    : state.evidently?.isFreeTextSearchOn?.boolValue;

export default evidentlySlice.reducer;
