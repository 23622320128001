import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { Link } from "react-router-dom";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useSnackbar } from 'notistack';

import { sendPersonalizeEvent, sendPutProjectEvent } from "../util";
import { addCourse, selectIsCourseAdded } from "../myCoursesSlice";
import { useDispatch, useSelector } from "react-redux";

const CourseCard = (props) => {
  const dispatch = useDispatch();
  const isCourseAdded = useSelector(selectIsCourseAdded);
  const { enqueueSnackbar } = useSnackbar();

  const handleAddCourse = React.useCallback(() => {
    enqueueSnackbar('Added Course!', { variant: 'default' });
    dispatch(addCourse(props.course.ITEM_ID));
    sendPutProjectEvent("ABAppProject", "addCourse", 1);
    sendPersonalizeEvent(props.course.ITEM_ID, "CourseAdded");
  }, []);

  return (
    <>
      <Card variant={props.variant || "elevation"}>
        <CardMedia
          children={
            <Box
              sx={{
                height: 140,
                display: "flex",
                alignItems: "center",
                textAlign: "center",
                placeContent: "center",
                fontSize: "1.5rem",
                position: "relative",
                color: "#00000073",
                lineHeight: 1,
                background: `${props.course.COLOR}4d`,
                fontWeight: props.course.FONT_WEIGHT,
                fontFamily: props.course.FONT_FAMILY,
                textTransform: props.course.TEXT_TRANSFORM,
                fontStyle: props.course.FONT_STYLE,
                "&::before": {
                  content: '""',
                  position: "absolute",
                  top: 0,
                  right: 0,
                  left: 0,
                  bottom: 0,
                  background:
                    "radial-gradient(farthest-side,white 30%,transparent 82%) 0 0/ 1px 4px",
                  webkitMask: "linear-gradient(to left,#000,transparent)",
                  mask: "linear-gradient(to left,#000,transparent)",
                },
              }}
            >
              {props.course.TITLE}
            </Box>
          }
          height="140"
          alt={props.course.TITLE}
        />
        <CardContent>
          <Typography
            title={props.course.TITLE}
            noWrap={true}
            gutterBottom
            variant="subtitle"
            component="div"
          >
            {props.course.TITLE}
          </Typography>
          <Stack direction="column" spacing={0}>
            <Typography
              title={props.course.CATEGORY}
              noWrap={true}
              variant="overline"
              component="div"
              sx={{ lineHeight: "inherit" }}
            >
              {props.course.CATEGORY}
            </Typography>
            <Typography
              title={props.course.SUBCATEGORY}
              noWrap={true}
              variant="subtitle2"
              component="div"
            >
              {props.course.SUBCATEGORY}
            </Typography>
          </Stack>
        </CardContent>
        <CardActions>
          <Button
            startIcon={<AddCircleOutlineIcon />}
            size="small"
            disabled={isCourseAdded(props.course.ITEM_ID)}
            onClick={handleAddCourse}
          >
            Add Course
          </Button>
          <Button
            startIcon={<ReadMoreIcon />}
            size="small"
            component={Link}
            to={`/courses/${props.course.ITEM_ID}`}
          >
            Learn More
          </Button>
        </CardActions>
      </Card>
    </>
  );
};

export default CourseCard;
