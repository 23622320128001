import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { CompanyIcon } from "./CustomIcons";
import { Outlet, Link, NavLink } from "react-router-dom";
import { ColorModeContext } from "./context";
import {
  indigo,
  green,
  deepOrange,
  purple,
  blueGrey,
} from "@mui/material/colors";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";

const ButtonLink = (props) => {
  return (
    <NavLink
      style={{ textDecoration: "none", margin: "0 4px" }}
      children={({ isActive }) => (
        <Button
          sx={{
            my: 2,
            color: "white",
            display: "block",
            borderRadius: "0px",
            fontWeight: isActive ? "bold" : "inherit",
            borderBottom: isActive ? "1px dashed white" : "inherit",
            marginBottom: isActive ? "-1px" : "16px",
          }}
        >
          {props.title}
        </Button>
      )}
      to={`${props.path}`}
    />
  );
};

const ChangeThemeButton = (props) => {
  const colorMode = React.useContext(ColorModeContext);

  return (
    <IconButton
      variant="contained"
      sx={{ color: props.color }}
      size="small"
      onClick={() => colorMode.changeColorMode(props.mode)}
    >
      <ChangeCircleIcon />
    </IconButton>
  );
};

const Layout = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <>
      <AppBar position="fixed">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              sx={{ mr: 2, display: { xs: "none", md: "flex" }, flexGrow: 1 }}
              component={Link}
              to="/"
            >
              <CompanyIcon width="150px" height="100%" fill="white" />
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                <MenuItem component={Link} to="/" onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">Home</Typography>
                </MenuItem>
                <MenuItem
                  component={Link}
                  to="/courses"
                  onClick={handleCloseNavMenu}
                >
                  <Typography textAlign="center">Courses</Typography>
                </MenuItem>
                <MenuItem
                  component={Link}
                  to="/categories"
                  onClick={handleCloseNavMenu}
                >
                  <Typography textAlign="center">Explore Categories</Typography>
                </MenuItem>
                <MenuItem
                  component={Link}
                  to="/about"
                  onClick={handleCloseNavMenu}
                >
                  <Typography textAlign="center">About</Typography>
                </MenuItem>
              </Menu>
            </Box>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
              component={Link}
              to="/"
            >
              <CompanyIcon width="150px" height="100%" fill="white" />
            </Typography>
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              <ButtonLink title="Home" path="/" />
              <ButtonLink title="Courses" path="/courses" />
              <ButtonLink title="Explore Categories" path="/categories" />
              <ButtonLink title="About" path="/about" />
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Toolbar />
      <div
        style={{
          minHeight: "calc(100% - 65px)",
          boxSizing: "border-box",
          marginBottom: "-100px",
          paddingBottom: "100px",
        }}
      >
        <Outlet />
      </div>
      <footer
        style={{
          boxSizing: "border-box",
          height: "100px",
          backgroundColor: "#eee",
          borderTop: "1px solid #e0e0e0",
          paddingTop: "35px",
        }}
      >
        <div
          style={{
            textAlign: "center",
            fontStyle: "italic",
            fontSize: ".75rem",
          }}
        >
          Any Company Online Courses | © 2022 All Rights Reserved. | AB Project
          by @ohd
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <ChangeThemeButton color={green[700]} mode="green" />
          <ChangeThemeButton color={deepOrange[700]} mode="deepOrange" />
          <ChangeThemeButton color={purple[700]} mode="purple" />
          <ChangeThemeButton color={blueGrey[700]} mode="blueGrey" />
        </div>
      </footer>
    </>
  );
};
export default Layout;
