import { configureStore } from '@reduxjs/toolkit'
import courseReducer from './courseSlice'
import evidentlyReducer from './evidentlySlice'
import myCourseReducer from './myCoursesSlice'

export default configureStore({
  reducer: {
    course: courseReducer,
    evidently: evidentlyReducer,
    myCourses: myCourseReducer,
  },
})