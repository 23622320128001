import * as React from "react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Stack from "@mui/material/Stack";

const About = () => {
  React.useEffect(() => {
    setTimeout(() => {
      try {
        throw new Error("About page failed to load.");
      } catch (e) {
        throw e;
      }
    }, 100);
  }, []);
  return (
    <>
      <Stack sx={{ width: "100%" }} spacing={2}>
        <Alert severity="error" sx={{ margin: "20px" }}>
          <AlertTitle>Error</AlertTitle>
          About page failed to load. <strong>Try again later.</strong>
        </Alert>
      </Stack>
    </>
  );
};
export default About;
