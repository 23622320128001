import { indigo, green, deepOrange, purple, blueGrey } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

export const theme = (color) => createTheme({
  palette: {
    ...(color === "deepOrange"
      ? {
          primary: {
            main: deepOrange[700],
          },
          secondary: {
            main: indigo[500],
          },
        }
      : color === "purple"
      ? {
          primary: {
            main: purple[700],
          },
          secondary: {
            main: indigo[500],
          },
        }
      : color === "blueGrey"
      ? {
          primary: {
            main: blueGrey[700],
          },
          secondary: {
            main: indigo[500],
          },
        }
      : {
          primary: {
            main: green[700],
          },
          secondary: {
            main: indigo[500],
          },
        }),
  },
});
