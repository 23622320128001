import * as React from "react";
import { useSelector } from "react-redux";
import { Typography } from "@mui/material";
import { selectCategories, selectCoursesByCategory } from "../courseSlice";
import CourseCard from "../components/CourseCard";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

const Categories = () => {
  const coursesByCategory = useSelector(selectCoursesByCategory);
  const categories = useSelector(selectCategories);

  return (
    <Box sx={{ flexGrow: 1, padding: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4" noWrap component="div">
            Explore Categories
          </Typography>
        </Grid>
        {categories.map((category) => {
          return (
            <React.Fragment key={category}>
              <Grid item xs={12}>
                <Typography variant="h5" noWrap component="div">
                  {category}
                </Typography>
              </Grid>
              {coursesByCategory(category)
                .slice(0, 3)
                .map((course) => {
                  return (
                    <Grid key={course.ITEM_ID} item xs={12} md={4}>
                      <CourseCard course={course} />
                    </Grid>
                  );
                })}
            </React.Fragment>
          );
        })}
      </Grid>
    </Box>
  );
};
export default Categories;
