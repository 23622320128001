export const courseData = [
  {
    "ITEM_ID": 101,
    "TITLE": "Introduction to SOLIDWORKS",
    "SUBCATEGORY": "3D & Animation",
    "CATEGORY": "Design",
    "COLOR": "#6a1b9a",
    "FONT_FAMILY": "fantasy",
    "TEXT_TRANSFORM": "lowercase",
    "FONT_STYLE": "inherit",
    "FONT_WEIGHT": "inherit"
  },
  {
    "ITEM_ID": 102,
    "TITLE": "The Beginner’s Guide to Rigging in 3ds Max",
    "SUBCATEGORY": "3D & Animation",
    "CATEGORY": "Design",
    "COLOR": "#a982e0",
    "FONT_FAMILY": "fantasy",
    "TEXT_TRANSFORM": "uppercase",
    "FONT_STYLE": "italic",
    "FONT_WEIGHT": "bold"
  },
  {
    "ITEM_ID": 103,
    "TITLE": "Creating 3D environments in Blender",
    "SUBCATEGORY": "3D & Animation",
    "CATEGORY": "Design",
    "COLOR": "#815dc1",
    "FONT_FAMILY": "cursive",
    "TEXT_TRANSFORM": "inherit",
    "FONT_STYLE": "italic",
    "FONT_WEIGHT": "inherit"
  },
  {
    "ITEM_ID": 104,
    "TITLE": "System Design using Verilog",
    "SUBCATEGORY": "Design Tools",
    "CATEGORY": "Design",
    "COLOR": "#ba68e2",
    "FONT_FAMILY": "serif",
    "TEXT_TRANSFORM": "uppercase",
    "FONT_STYLE": "inherit",
    "FONT_WEIGHT": "bold"
  },
  {
    "ITEM_ID": 105,
    "TITLE": "Final Cut Pro X 101",
    "SUBCATEGORY": "Design Tools",
    "CATEGORY": "Design",
    "COLOR": "#b383f7",
    "FONT_FAMILY": "fantasy",
    "TEXT_TRANSFORM": "lowercase",
    "FONT_STYLE": "italic",
    "FONT_WEIGHT": "inherit"
  },
  {
    "ITEM_ID": 106,
    "TITLE": "Learn Marvelous Designer",
    "SUBCATEGORY": "Design Tools",
    "CATEGORY": "Design",
    "COLOR": "#d6abea",
    "FONT_FAMILY": "fantasy",
    "TEXT_TRANSFORM": "lowercase",
    "FONT_STYLE": "inherit",
    "FONT_WEIGHT": "bold"
  },
  {
    "ITEM_ID": 107,
    "TITLE": "The Ultimate Character Drawing Course - Beginner to Advanced",
    "SUBCATEGORY": "Game Design",
    "CATEGORY": "Design",
    "COLOR": "#a45ac4",
    "FONT_FAMILY": "fantasy",
    "TEXT_TRANSFORM": "uppercase",
    "FONT_STYLE": "italic",
    "FONT_WEIGHT": "inherit"
  },
  {
    "ITEM_ID": 108,
    "TITLE": "Game Writing: Storytelling through Video Game Design",
    "SUBCATEGORY": "Game Design",
    "CATEGORY": "Design",
    "COLOR": "#985dba",
    "FONT_FAMILY": "cursive",
    "TEXT_TRANSFORM": "uppercase",
    "FONT_STYLE": "italic",
    "FONT_WEIGHT": "inherit"
  },
  {
    "ITEM_ID": 109,
    "TITLE": "Pixel art for Video games",
    "SUBCATEGORY": "Game Design",
    "CATEGORY": "Design",
    "COLOR": "#c994ef",
    "FONT_FAMILY": "monospace",
    "TEXT_TRANSFORM": "uppercase",
    "FONT_STYLE": "inherit",
    "FONT_WEIGHT": "bold"
  },
  {
    "ITEM_ID": 110,
    "TITLE": "Unreal Engine: Character Skill System",
    "SUBCATEGORY": "Game Design",
    "CATEGORY": "Design",
    "COLOR": "#38026b",
    "FONT_FAMILY": "monospace",
    "TEXT_TRANSFORM": "inherit",
    "FONT_STYLE": "inherit",
    "FONT_WEIGHT": "inherit"
  },
  {
    "ITEM_ID": 111,
    "TITLE": "The Complete Graphic Design Theory for Beginners Course",
    "SUBCATEGORY": "Graphic Design",
    "CATEGORY": "Design",
    "COLOR": "#490a70",
    "FONT_FAMILY": "fantasy",
    "TEXT_TRANSFORM": "lowercase",
    "FONT_STYLE": "italic",
    "FONT_WEIGHT": "bold"
  },
  {
    "ITEM_ID": 112,
    "TITLE": "Become A Logo Designer",
    "SUBCATEGORY": "Graphic Design",
    "CATEGORY": "Design",
    "COLOR": "#b392db",
    "FONT_FAMILY": "serif",
    "TEXT_TRANSFORM": "inherit",
    "FONT_STYLE": "italic",
    "FONT_WEIGHT": "bold"
  },
  {
    "ITEM_ID": 113,
    "TITLE": "Essential Skills for Designers - Masking",
    "SUBCATEGORY": "Graphic Design",
    "CATEGORY": "Design",
    "COLOR": "#b572d8",
    "FONT_FAMILY": "serif",
    "TEXT_TRANSFORM": "uppercase",
    "FONT_STYLE": "inherit",
    "FONT_WEIGHT": "bold"
  },
  {
    "ITEM_ID": 114,
    "TITLE": "The Complete Guide to Designing a Mobile App",
    "SUBCATEGORY": "Mobile Design",
    "CATEGORY": "Design",
    "COLOR": "#9c77ea",
    "FONT_FAMILY": "monospace",
    "TEXT_TRANSFORM": "uppercase",
    "FONT_STYLE": "inherit",
    "FONT_WEIGHT": "bold"
  },
  {
    "ITEM_ID": 115,
    "TITLE": "Effective iOS & Android App Icon Design",
    "SUBCATEGORY": "Mobile Design",
    "CATEGORY": "Design",
    "COLOR": "#5f35ba",
    "FONT_FAMILY": "serif",
    "TEXT_TRANSFORM": "uppercase",
    "FONT_STYLE": "inherit",
    "FONT_WEIGHT": "inherit"
  },
  {
    "ITEM_ID": 116,
    "TITLE": "User Experience Design Fundamentals",
    "SUBCATEGORY": "User Experience Design",
    "CATEGORY": "Design",
    "COLOR": "#9069ce",
    "FONT_FAMILY": "fantasy",
    "TEXT_TRANSFORM": "lowercase",
    "FONT_STYLE": "inherit",
    "FONT_WEIGHT": "bold"
  },
  {
    "ITEM_ID": 117,
    "TITLE": "UX Strategy Fundamentals",
    "SUBCATEGORY": "User Experience Design",
    "CATEGORY": "Design",
    "COLOR": "#c673ef",
    "FONT_FAMILY": "cursive",
    "TEXT_TRANSFORM": "inherit",
    "FONT_STYLE": "inherit",
    "FONT_WEIGHT": "bold"
  },
  {
    "ITEM_ID": 118,
    "TITLE": "The Simple Way to Conduct a UX Audit",
    "SUBCATEGORY": "User Experience Design",
    "CATEGORY": "Design",
    "COLOR": "#cdb8fc",
    "FONT_FAMILY": "monospace",
    "TEXT_TRANSFORM": "uppercase",
    "FONT_STYLE": "italic",
    "FONT_WEIGHT": "bold"
  },
  {
    "ITEM_ID": 119,
    "TITLE": "Beginners Guide to Wireframes - A Mini Course",
    "SUBCATEGORY": "Web Design",
    "CATEGORY": "Design",
    "COLOR": "#5623a8",
    "FONT_FAMILY": "monospace",
    "TEXT_TRANSFORM": "uppercase",
    "FONT_STYLE": "inherit",
    "FONT_WEIGHT": "bold"
  },
  {
    "ITEM_ID": 120,
    "TITLE": "Creating a Responsive Web Design",
    "SUBCATEGORY": "Web Design",
    "CATEGORY": "Design",
    "COLOR": "#c899ef",
    "FONT_FAMILY": "cursive",
    "TEXT_TRANSFORM": "lowercase",
    "FONT_STYLE": "italic",
    "FONT_WEIGHT": "bold"
  },
  {
    "ITEM_ID": 121,
    "TITLE": "Landing Page Design & Conversion Rate Optimization",
    "SUBCATEGORY": "Web Design",
    "CATEGORY": "Design",
    "COLOR": "#be91e0",
    "FONT_FAMILY": "monospace",
    "TEXT_TRANSFORM": "inherit",
    "FONT_STYLE": "italic",
    "FONT_WEIGHT": "inherit"
  },
  {
    "ITEM_ID": 201,
    "TITLE": "Data Warehouse Fundamentals for Beginners",
    "SUBCATEGORY": "Database Design & Development",
    "CATEGORY": "Development",
    "COLOR": "#3ba7ce",
    "FONT_FAMILY": "cursive",
    "TEXT_TRANSFORM": "inherit",
    "FONT_STYLE": "italic",
    "FONT_WEIGHT": "bold"
  },
  {
    "ITEM_ID": 202,
    "TITLE": "Relational Database Design",
    "SUBCATEGORY": "Database Design & Development",
    "CATEGORY": "Development",
    "COLOR": "#4979bc",
    "FONT_FAMILY": "monospace",
    "TEXT_TRANSFORM": "lowercase",
    "FONT_STYLE": "italic",
    "FONT_WEIGHT": "inherit"
  },
  {
    "ITEM_ID": 203,
    "TITLE": "Introduction to Blockchain with Industry Applications",
    "SUBCATEGORY": "Database Design & Development",
    "CATEGORY": "Development",
    "COLOR": "#d1ccff",
    "FONT_FAMILY": "fantasy",
    "TEXT_TRANSFORM": "lowercase",
    "FONT_STYLE": "italic",
    "FONT_WEIGHT": "bold"
  },
  {
    "ITEM_ID": 204,
    "TITLE": "Docker and Kubernetes: The Complete Guide",
    "SUBCATEGORY": "Development Tools",
    "CATEGORY": "Development",
    "COLOR": "#2662c9",
    "FONT_FAMILY": "monospace",
    "TEXT_TRANSFORM": "inherit",
    "FONT_STYLE": "inherit",
    "FONT_WEIGHT": "bold"
  },
  {
    "ITEM_ID": 205,
    "TITLE": "The Git & Github Bootcamp",
    "SUBCATEGORY": "Development Tools",
    "CATEGORY": "Development",
    "COLOR": "#23298e",
    "FONT_FAMILY": "fantasy",
    "TEXT_TRANSFORM": "uppercase",
    "FONT_STYLE": "italic",
    "FONT_WEIGHT": "inherit"
  },
  {
    "ITEM_ID": 206,
    "TITLE": "Vim Masterclass",
    "SUBCATEGORY": "Development Tools",
    "CATEGORY": "Development",
    "COLOR": "#6da5e0",
    "FONT_FAMILY": "fantasy",
    "TEXT_TRANSFORM": "lowercase",
    "FONT_STYLE": "inherit",
    "FONT_WEIGHT": "inherit"
  },
  {
    "ITEM_ID": 207,
    "TITLE": "Learn To Create An RPG Game In Unity",
    "SUBCATEGORY": "Game Development",
    "CATEGORY": "Development",
    "COLOR": "#292ece",
    "FONT_FAMILY": "serif",
    "TEXT_TRANSFORM": "uppercase",
    "FONT_STYLE": "inherit",
    "FONT_WEIGHT": "inherit"
  },
  {
    "ITEM_ID": 208,
    "TITLE": "Design Patterns for Game Programming",
    "SUBCATEGORY": "Game Development",
    "CATEGORY": "Development",
    "COLOR": "#adeef4",
    "FONT_FAMILY": "cursive",
    "TEXT_TRANSFORM": "inherit",
    "FONT_STYLE": "inherit",
    "FONT_WEIGHT": "inherit"
  },
  {
    "ITEM_ID": 209,
    "TITLE": "Improve Your Game Design With Better Gameplay",
    "SUBCATEGORY": "Game Development",
    "CATEGORY": "Development",
    "COLOR": "#2589c6",
    "FONT_FAMILY": "monospace",
    "TEXT_TRANSFORM": "uppercase",
    "FONT_STYLE": "italic",
    "FONT_WEIGHT": "inherit"
  },
  {
    "ITEM_ID": 210,
    "TITLE": "Unit Testing Swift Mobile App",
    "SUBCATEGORY": "Mobile Development",
    "CATEGORY": "Development",
    "COLOR": "#153d87",
    "FONT_FAMILY": "cursive",
    "TEXT_TRANSFORM": "uppercase",
    "FONT_STYLE": "inherit",
    "FONT_WEIGHT": "inherit"
  },
  {
    "ITEM_ID": 211,
    "TITLE": "React Native: Advanced Concepts",
    "SUBCATEGORY": "Mobile Development",
    "CATEGORY": "Development",
    "COLOR": "#4367a5",
    "FONT_FAMILY": "serif",
    "TEXT_TRANSFORM": "inherit",
    "FONT_STYLE": "italic",
    "FONT_WEIGHT": "bold"
  },
  {
    "ITEM_ID": 212,
    "TITLE": "Android Architecture Masterclass",
    "SUBCATEGORY": "Mobile Development",
    "CATEGORY": "Development",
    "COLOR": "#29478e",
    "FONT_FAMILY": "monospace",
    "TEXT_TRANSFORM": "lowercase",
    "FONT_STYLE": "italic",
    "FONT_WEIGHT": "bold"
  },
  {
    "ITEM_ID": 213,
    "TITLE": "Advanced Javascript",
    "SUBCATEGORY": "Programming Languages",
    "CATEGORY": "Development",
    "COLOR": "#76c6e0",
    "FONT_FAMILY": "cursive",
    "TEXT_TRANSFORM": "inherit",
    "FONT_STYLE": "italic",
    "FONT_WEIGHT": "inherit"
  },
  {
    "ITEM_ID": 214,
    "TITLE": "Learn Python Programming Masterclass",
    "SUBCATEGORY": "Programming Languages",
    "CATEGORY": "Development",
    "COLOR": "#4fd5f7",
    "FONT_FAMILY": "fantasy",
    "TEXT_TRANSFORM": "inherit",
    "FONT_STYLE": "inherit",
    "FONT_WEIGHT": "inherit"
  },
  {
    "ITEM_ID": 215,
    "TITLE": "Design Patterns in Swift",
    "SUBCATEGORY": "Programming Languages",
    "CATEGORY": "Development",
    "COLOR": "#cce2ff",
    "FONT_FAMILY": "fantasy",
    "TEXT_TRANSFORM": "uppercase",
    "FONT_STYLE": "inherit",
    "FONT_WEIGHT": "inherit"
  },
  {
    "ITEM_ID": 216,
    "TITLE": "Clean Code",
    "SUBCATEGORY": "Software Engineering",
    "CATEGORY": "Development",
    "COLOR": "#6dcfd6",
    "FONT_FAMILY": "cursive",
    "TEXT_TRANSFORM": "uppercase",
    "FONT_STYLE": "inherit",
    "FONT_WEIGHT": "bold"
  },
  {
    "ITEM_ID": 217,
    "TITLE": "Pragmatic System Design",
    "SUBCATEGORY": "Software Engineering",
    "CATEGORY": "Development",
    "COLOR": "#5163d6",
    "FONT_FAMILY": "cursive",
    "TEXT_TRANSFORM": "lowercase",
    "FONT_STYLE": "italic",
    "FONT_WEIGHT": "inherit"
  },
  {
    "ITEM_ID": 218,
    "TITLE": "Software Architecture Case Studies",
    "SUBCATEGORY": "Software Engineering",
    "CATEGORY": "Development",
    "COLOR": "#0a4caf",
    "FONT_FAMILY": "monospace",
    "TEXT_TRANSFORM": "lowercase",
    "FONT_STYLE": "inherit",
    "FONT_WEIGHT": "inherit"
  },
  {
    "ITEM_ID": 219,
    "TITLE": "The Complete 2022 Software Testing Bootcamp",
    "SUBCATEGORY": "Software Testing",
    "CATEGORY": "Development",
    "COLOR": "#5ba6cc",
    "FONT_FAMILY": "fantasy",
    "TEXT_TRANSFORM": "lowercase",
    "FONT_STYLE": "inherit",
    "FONT_WEIGHT": "bold"
  },
  {
    "ITEM_ID": 220,
    "TITLE": "Mobile Testing Masterclass From The Beginning",
    "SUBCATEGORY": "Software Testing",
    "CATEGORY": "Development",
    "COLOR": "#c1bafc",
    "FONT_FAMILY": "monospace",
    "TEXT_TRANSFORM": "uppercase",
    "FONT_STYLE": "inherit",
    "FONT_WEIGHT": "bold"
  },
  {
    "ITEM_ID": 221,
    "TITLE": "Learn TDD in 24 hours",
    "SUBCATEGORY": "Software Testing",
    "CATEGORY": "Development",
    "COLOR": "#3857e2",
    "FONT_FAMILY": "fantasy",
    "TEXT_TRANSFORM": "inherit",
    "FONT_STYLE": "inherit",
    "FONT_WEIGHT": "inherit"
  },
  {
    "ITEM_ID": 222,
    "TITLE": "API and Web Service Introduction",
    "SUBCATEGORY": "Web Development",
    "CATEGORY": "Development",
    "COLOR": "#6e48f9",
    "FONT_FAMILY": "serif",
    "TEXT_TRANSFORM": "inherit",
    "FONT_STYLE": "italic",
    "FONT_WEIGHT": "inherit"
  },
  {
    "ITEM_ID": 223,
    "TITLE": "Creating Accessible Websites",
    "SUBCATEGORY": "Web Development",
    "CATEGORY": "Development",
    "COLOR": "#bee2f7",
    "FONT_FAMILY": "fantasy",
    "TEXT_TRANSFORM": "lowercase",
    "FONT_STYLE": "italic",
    "FONT_WEIGHT": "inherit"
  },
  {
    "ITEM_ID": 224,
    "TITLE": "Build Responsive Real-World Websites with HTML and CSS",
    "SUBCATEGORY": "Web Development",
    "CATEGORY": "Development",
    "COLOR": "#9fa5ea",
    "FONT_FAMILY": "cursive",
    "TEXT_TRANSFORM": "inherit",
    "FONT_STYLE": "inherit",
    "FONT_WEIGHT": "inherit"
  },
  {
    "ITEM_ID": 301,
    "TITLE": "Forensic Accounting: Fraud Investigation!",
    "SUBCATEGORY": "Accounting",
    "CATEGORY": "Finance & Accounting",
    "COLOR": "#c43562",
    "FONT_FAMILY": "serif",
    "TEXT_TRANSFORM": "uppercase",
    "FONT_STYLE": "inherit",
    "FONT_WEIGHT": "bold"
  },
  {
    "ITEM_ID": 302,
    "TITLE": "Audit - Financial Statement",
    "SUBCATEGORY": "Accounting",
    "CATEGORY": "Finance & Accounting",
    "COLOR": "#bc2d20",
    "FONT_FAMILY": "monospace",
    "TEXT_TRANSFORM": "lowercase",
    "FONT_STYLE": "inherit",
    "FONT_WEIGHT": "bold"
  },
  {
    "ITEM_ID": 303,
    "TITLE": "Fundamentals of Business Taxes",
    "SUBCATEGORY": "Accounting",
    "CATEGORY": "Finance & Accounting",
    "COLOR": "#f984a1",
    "FONT_FAMILY": "monospace",
    "TEXT_TRANSFORM": "lowercase",
    "FONT_STYLE": "italic",
    "FONT_WEIGHT": "inherit"
  },
  {
    "ITEM_ID": 304,
    "TITLE": "Mastering QuickBooks Online",
    "SUBCATEGORY": "Accounting Software",
    "CATEGORY": "Finance & Accounting",
    "COLOR": "#aa0800",
    "FONT_FAMILY": "fantasy",
    "TEXT_TRANSFORM": "lowercase",
    "FONT_STYLE": "italic",
    "FONT_WEIGHT": "inherit"
  },
  {
    "ITEM_ID": 305,
    "TITLE": "SAP HANA Administration",
    "SUBCATEGORY": "Accounting Software",
    "CATEGORY": "Finance & Accounting",
    "COLOR": "#fc8389",
    "FONT_FAMILY": "cursive",
    "TEXT_TRANSFORM": "lowercase",
    "FONT_STYLE": "inherit",
    "FONT_WEIGHT": "bold"
  },
  {
    "ITEM_ID": 306,
    "TITLE": "Business Banking 101",
    "SUBCATEGORY": "Corporate Finance",
    "CATEGORY": "Finance & Accounting",
    "COLOR": "#96221c",
    "FONT_FAMILY": "fantasy",
    "TEXT_TRANSFORM": "lowercase",
    "FONT_STYLE": "italic",
    "FONT_WEIGHT": "bold"
  },
  {
    "ITEM_ID": 307,
    "TITLE": "The Corporate Finance Course 2022",
    "SUBCATEGORY": "Corporate Finance",
    "CATEGORY": "Finance & Accounting",
    "COLOR": "#d34110",
    "FONT_FAMILY": "fantasy",
    "TEXT_TRANSFORM": "lowercase",
    "FONT_STYLE": "inherit",
    "FONT_WEIGHT": "bold"
  },
  {
    "ITEM_ID": 308,
    "TITLE": "Grant writing - Improve your win rate",
    "SUBCATEGORY": "Corporate Finance",
    "CATEGORY": "Finance & Accounting",
    "COLOR": "#c91e68",
    "FONT_FAMILY": "cursive",
    "TEXT_TRANSFORM": "inherit",
    "FONT_STYLE": "italic",
    "FONT_WEIGHT": "bold"
  },
  {
    "ITEM_ID": 309,
    "TITLE": "Finance for Non-Finance: Learn Quick and Easy",
    "SUBCATEGORY": "Finance Fundamentals",
    "CATEGORY": "Finance & Accounting",
    "COLOR": "#d6445f",
    "FONT_FAMILY": "serif",
    "TEXT_TRANSFORM": "lowercase",
    "FONT_STYLE": "italic",
    "FONT_WEIGHT": "inherit"
  },
  {
    "ITEM_ID": 310,
    "TITLE": "Budgeting for Business",
    "SUBCATEGORY": "Finance Fundamentals",
    "CATEGORY": "Finance & Accounting",
    "COLOR": "#e03f3a",
    "FONT_FAMILY": "serif",
    "TEXT_TRANSFORM": "lowercase",
    "FONT_STYLE": "italic",
    "FONT_WEIGHT": "inherit"
  },
  {
    "ITEM_ID": 311,
    "TITLE": "Building an Organizational Budget",
    "SUBCATEGORY": "Finance Fundamentals",
    "CATEGORY": "Finance & Accounting",
    "COLOR": "#c11d3e",
    "FONT_FAMILY": "fantasy",
    "TEXT_TRANSFORM": "inherit",
    "FONT_STYLE": "italic",
    "FONT_WEIGHT": "bold"
  },
  {
    "ITEM_ID": 312,
    "TITLE": "Understand Banks & Financial Markets",
    "SUBCATEGORY": "Financial Modeling & Analysis",
    "CATEGORY": "Finance & Accounting",
    "COLOR": "#cc5647",
    "FONT_FAMILY": "cursive",
    "TEXT_TRANSFORM": "inherit",
    "FONT_STYLE": "inherit",
    "FONT_WEIGHT": "inherit"
  },
  {
    "ITEM_ID": 313,
    "TITLE": "Credit Risk Modeling in Python 2022",
    "SUBCATEGORY": "Financial Modeling & Analysis",
    "CATEGORY": "Finance & Accounting",
    "COLOR": "#a32d27",
    "FONT_FAMILY": "monospace",
    "TEXT_TRANSFORM": "uppercase",
    "FONT_STYLE": "inherit",
    "FONT_WEIGHT": "inherit"
  },
  {
    "ITEM_ID": 314,
    "TITLE": "The Credit Analyst Training course",
    "SUBCATEGORY": "Financial Modeling & Analysis",
    "CATEGORY": "Finance & Accounting",
    "COLOR": "#d8175e",
    "FONT_FAMILY": "monospace",
    "TEXT_TRANSFORM": "inherit",
    "FONT_STYLE": "italic",
    "FONT_WEIGHT": "bold"
  },
  {
    "ITEM_ID": 315,
    "TITLE": "The Complete Foundation Stock Trading Course",
    "SUBCATEGORY": "Investing & Trading",
    "CATEGORY": "Finance & Accounting",
    "COLOR": "#ea5452",
    "FONT_FAMILY": "fantasy",
    "TEXT_TRANSFORM": "uppercase",
    "FONT_STYLE": "inherit",
    "FONT_WEIGHT": "inherit"
  },
  {
    "ITEM_ID": 316,
    "TITLE": "The Securities Trade Lifecycle",
    "SUBCATEGORY": "Investing & Trading",
    "CATEGORY": "Finance & Accounting",
    "COLOR": "#a02f09",
    "FONT_FAMILY": "fantasy",
    "TEXT_TRANSFORM": "lowercase",
    "FONT_STYLE": "italic",
    "FONT_WEIGHT": "bold"
  },
  {
    "ITEM_ID": 317,
    "TITLE": "Capital Market Immersion",
    "SUBCATEGORY": "Investing & Trading",
    "CATEGORY": "Finance & Accounting",
    "COLOR": "#f46492",
    "FONT_FAMILY": "serif",
    "TEXT_TRANSFORM": "uppercase",
    "FONT_STYLE": "italic",
    "FONT_WEIGHT": "inherit"
  },
  {
    "ITEM_ID": 401,
    "TITLE": "Feedback is Fuel",
    "SUBCATEGORY": "Communications",
    "CATEGORY": "Leadership & Management",
    "COLOR": "#f4ef50",
    "FONT_FAMILY": "monospace",
    "TEXT_TRANSFORM": "uppercase",
    "FONT_STYLE": "italic",
    "FONT_WEIGHT": "inherit"
  },
  {
    "ITEM_ID": 402,
    "TITLE": "Conscious Listening",
    "SUBCATEGORY": "Communications",
    "CATEGORY": "Leadership & Management",
    "COLOR": "#fff8b5",
    "FONT_FAMILY": "serif",
    "TEXT_TRANSFORM": "lowercase",
    "FONT_STYLE": "italic",
    "FONT_WEIGHT": "bold"
  },
  {
    "ITEM_ID": 403,
    "TITLE": "Storytelling to Influence",
    "SUBCATEGORY": "Communications",
    "CATEGORY": "Leadership & Management",
    "COLOR": "#f9eda9",
    "FONT_FAMILY": "monospace",
    "TEXT_TRANSFORM": "uppercase",
    "FONT_STYLE": "italic",
    "FONT_WEIGHT": "bold"
  },
  {
    "ITEM_ID": 404,
    "TITLE": "Psychology of Diversity and Unconscious Bias",
    "SUBCATEGORY": "Diversity Equity & Inclusion",
    "CATEGORY": "Leadership & Management",
    "COLOR": "#d8bd50",
    "FONT_FAMILY": "monospace",
    "TEXT_TRANSFORM": "lowercase",
    "FONT_STYLE": "italic",
    "FONT_WEIGHT": "inherit"
  },
  {
    "ITEM_ID": 405,
    "TITLE": "Manage Diversity",
    "SUBCATEGORY": "Diversity Equity & Inclusion",
    "CATEGORY": "Leadership & Management",
    "COLOR": "#d3bf0a",
    "FONT_FAMILY": "fantasy",
    "TEXT_TRANSFORM": "inherit",
    "FONT_STYLE": "inherit",
    "FONT_WEIGHT": "inherit"
  },
  {
    "ITEM_ID": 406,
    "TITLE": "Navigate Diversity",
    "SUBCATEGORY": "Diversity Equity & Inclusion",
    "CATEGORY": "Leadership & Management",
    "COLOR": "#f7ec74",
    "FONT_FAMILY": "serif",
    "TEXT_TRANSFORM": "inherit",
    "FONT_STYLE": "inherit",
    "FONT_WEIGHT": "bold"
  },
  {
    "ITEM_ID": 407,
    "TITLE": "The Future of Energy",
    "SUBCATEGORY": "Leadership",
    "CATEGORY": "Leadership & Management",
    "COLOR": "#fffcb5",
    "FONT_FAMILY": "serif",
    "TEXT_TRANSFORM": "uppercase",
    "FONT_STYLE": "inherit",
    "FONT_WEIGHT": "inherit"
  },
  {
    "ITEM_ID": 408,
    "TITLE": "Leadership Masterclass",
    "SUBCATEGORY": "Leadership",
    "CATEGORY": "Leadership & Management",
    "COLOR": "#d8b91e",
    "FONT_FAMILY": "monospace",
    "TEXT_TRANSFORM": "lowercase",
    "FONT_STYLE": "italic",
    "FONT_WEIGHT": "inherit"
  },
  {
    "ITEM_ID": 409,
    "TITLE": "Roadmap to Leadership",
    "SUBCATEGORY": "Leadership",
    "CATEGORY": "Leadership & Management",
    "COLOR": "#eadf60",
    "FONT_FAMILY": "monospace",
    "TEXT_TRANSFORM": "inherit",
    "FONT_STYLE": "inherit",
    "FONT_WEIGHT": "inherit"
  },
  {
    "ITEM_ID": 410,
    "TITLE": "Strategic Innovation",
    "SUBCATEGORY": "Leadership",
    "CATEGORY": "Leadership & Management",
    "COLOR": "#e8d214",
    "FONT_FAMILY": "fantasy",
    "TEXT_TRANSFORM": "uppercase",
    "FONT_STYLE": "inherit",
    "FONT_WEIGHT": "bold"
  },
  {
    "ITEM_ID": 411,
    "TITLE": "Coaching for Growth and Success",
    "SUBCATEGORY": "Management",
    "CATEGORY": "Leadership & Management",
    "COLOR": "#f7f094",
    "FONT_FAMILY": "fantasy",
    "TEXT_TRANSFORM": "lowercase",
    "FONT_STYLE": "italic",
    "FONT_WEIGHT": "bold"
  },
  {
    "ITEM_ID": 412,
    "TITLE": "Leading Effective Meetings",
    "SUBCATEGORY": "Management",
    "CATEGORY": "Leadership & Management",
    "COLOR": "#d2d824",
    "FONT_FAMILY": "monospace",
    "TEXT_TRANSFORM": "inherit",
    "FONT_STYLE": "inherit",
    "FONT_WEIGHT": "inherit"
  },
  {
    "ITEM_ID": 413,
    "TITLE": "Building Influence at Work",
    "SUBCATEGORY": "Management",
    "CATEGORY": "Leadership & Management",
    "COLOR": "#e6e87a",
    "FONT_FAMILY": "cursive",
    "TEXT_TRANSFORM": "uppercase",
    "FONT_STYLE": "inherit",
    "FONT_WEIGHT": "bold"
  },
  {
    "ITEM_ID": 414,
    "TITLE": "Critical Thinking Strategies For Better Decisions",
    "SUBCATEGORY": "Strategy",
    "CATEGORY": "Leadership & Management",
    "COLOR": "#fcf7b3",
    "FONT_FAMILY": "fantasy",
    "TEXT_TRANSFORM": "lowercase",
    "FONT_STYLE": "inherit",
    "FONT_WEIGHT": "bold"
  },
  {
    "ITEM_ID": 415,
    "TITLE": "Design Thinking in 3 Steps",
    "SUBCATEGORY": "Strategy",
    "CATEGORY": "Leadership & Management",
    "COLOR": "#f2d532",
    "FONT_FAMILY": "cursive",
    "TEXT_TRANSFORM": "inherit",
    "FONT_STYLE": "inherit",
    "FONT_WEIGHT": "bold"
  },
  {
    "ITEM_ID": 416,
    "TITLE": "Consulting Approach to Problem Solving",
    "SUBCATEGORY": "Strategy",
    "CATEGORY": "Leadership & Management",
    "COLOR": "#f9f8a7",
    "FONT_FAMILY": "cursive",
    "TEXT_TRANSFORM": "inherit",
    "FONT_STYLE": "italic",
    "FONT_WEIGHT": "bold"
  },
  {
    "ITEM_ID": 501,
    "TITLE": "Modern Copywriting: Writing copy that sells in 2021",
    "SUBCATEGORY": "Content Marketing",
    "CATEGORY": "Marketing",
    "COLOR": "#70f9c7",
    "FONT_FAMILY": "monospace",
    "TEXT_TRANSFORM": "uppercase",
    "FONT_STYLE": "italic",
    "FONT_WEIGHT": "bold"
  },
  {
    "ITEM_ID": 502,
    "TITLE": "Tech Writers Master Class",
    "SUBCATEGORY": "Content Marketing",
    "CATEGORY": "Marketing",
    "COLOR": "#42f471",
    "FONT_FAMILY": "serif",
    "TEXT_TRANSFORM": "inherit",
    "FONT_STYLE": "italic",
    "FONT_WEIGHT": "bold"
  },
  {
    "ITEM_ID": 503,
    "TITLE": "Build a Lucrative Copywriting Portfolio With Ease",
    "SUBCATEGORY": "Content Marketing",
    "CATEGORY": "Marketing",
    "COLOR": "#49cc8a",
    "FONT_FAMILY": "serif",
    "TEXT_TRANSFORM": "lowercase",
    "FONT_STYLE": "italic",
    "FONT_WEIGHT": "inherit"
  },
  {
    "ITEM_ID": 504,
    "TITLE": "Digital Marketing 101",
    "SUBCATEGORY": "Digital Marketing",
    "CATEGORY": "Marketing",
    "COLOR": "#62d83e",
    "FONT_FAMILY": "cursive",
    "TEXT_TRANSFORM": "lowercase",
    "FONT_STYLE": "italic",
    "FONT_WEIGHT": "inherit"
  },
  {
    "ITEM_ID": 505,
    "TITLE": "The Ultimate Digital Marketing Bootcamp",
    "SUBCATEGORY": "Digital Marketing",
    "CATEGORY": "Marketing",
    "COLOR": "#f3ffa8",
    "FONT_FAMILY": "fantasy",
    "TEXT_TRANSFORM": "uppercase",
    "FONT_STYLE": "inherit",
    "FONT_WEIGHT": "bold"
  },
  {
    "ITEM_ID": 506,
    "TITLE": "Growth hacks for entrepreneurs",
    "SUBCATEGORY": "Digital Marketing",
    "CATEGORY": "Marketing",
    "COLOR": "#95f9ab",
    "FONT_FAMILY": "serif",
    "TEXT_TRANSFORM": "inherit",
    "FONT_STYLE": "italic",
    "FONT_WEIGHT": "inherit"
  },
  {
    "ITEM_ID": 507,
    "TITLE": "A/B Testing and Experimentation for Beginners",
    "SUBCATEGORY": "Marketing Analytics & Automation",
    "CATEGORY": "Marketing",
    "COLOR": "#7cffe4",
    "FONT_FAMILY": "cursive",
    "TEXT_TRANSFORM": "uppercase",
    "FONT_STYLE": "italic",
    "FONT_WEIGHT": "inherit"
  },
  {
    "ITEM_ID": 508,
    "TITLE": "Introduction to Modern Marketing Analytics",
    "SUBCATEGORY": "Marketing Analytics & Automation",
    "CATEGORY": "Marketing",
    "COLOR": "#e7ff72",
    "FONT_FAMILY": "cursive",
    "TEXT_TRANSFORM": "inherit",
    "FONT_STYLE": "italic",
    "FONT_WEIGHT": "bold"
  },
  {
    "ITEM_ID": 509,
    "TITLE": "Product Management 101",
    "SUBCATEGORY": "Marketing Strategy",
    "CATEGORY": "Marketing",
    "COLOR": "#61d367",
    "FONT_FAMILY": "monospace",
    "TEXT_TRANSFORM": "inherit",
    "FONT_STYLE": "italic",
    "FONT_WEIGHT": "inherit"
  },
  {
    "ITEM_ID": 510,
    "TITLE": "How To Build A Brand On Social Media",
    "SUBCATEGORY": "Marketing Strategy",
    "CATEGORY": "Marketing",
    "COLOR": "#58ad27",
    "FONT_FAMILY": "fantasy",
    "TEXT_TRANSFORM": "lowercase",
    "FONT_STYLE": "inherit",
    "FONT_WEIGHT": "inherit"
  },
  {
    "ITEM_ID": 511,
    "TITLE": "Intro to Branding",
    "SUBCATEGORY": "Marketing Strategy",
    "CATEGORY": "Marketing",
    "COLOR": "#38eaa6",
    "FONT_FAMILY": "serif",
    "TEXT_TRANSFORM": "lowercase",
    "FONT_STYLE": "italic",
    "FONT_WEIGHT": "inherit"
  },
  {
    "ITEM_ID": 512,
    "TITLE": "The Webinar Selling System",
    "SUBCATEGORY": "Paid Advertising",
    "CATEGORY": "Marketing",
    "COLOR": "#27c45b",
    "FONT_FAMILY": "cursive",
    "TEXT_TRANSFORM": "inherit",
    "FONT_STYLE": "inherit",
    "FONT_WEIGHT": "inherit"
  },
  {
    "ITEM_ID": 513,
    "TITLE": "Event Management for Beginners",
    "SUBCATEGORY": "Paid Advertising",
    "CATEGORY": "Marketing",
    "COLOR": "#52ce96",
    "FONT_FAMILY": "cursive",
    "TEXT_TRANSFORM": "lowercase",
    "FONT_STYLE": "italic",
    "FONT_WEIGHT": "bold"
  },
  {
    "ITEM_ID": 514,
    "TITLE": "Digital Marketing: How to Generate Sales Leads",
    "SUBCATEGORY": "Paid Advertising",
    "CATEGORY": "Marketing",
    "COLOR": "#94ef97",
    "FONT_FAMILY": "cursive",
    "TEXT_TRANSFORM": "inherit",
    "FONT_STYLE": "inherit",
    "FONT_WEIGHT": "bold"
  },
  {
    "ITEM_ID": 515,
    "TITLE": "SEO Tutorial for Beginners",
    "SUBCATEGORY": "Search Engine Optimization",
    "CATEGORY": "Marketing",
    "COLOR": "#abfcce",
    "FONT_FAMILY": "cursive",
    "TEXT_TRANSFORM": "inherit",
    "FONT_STYLE": "italic",
    "FONT_WEIGHT": "bold"
  },
  {
    "ITEM_ID": 516,
    "TITLE": "The Complete SEO Bootcamp",
    "SUBCATEGORY": "Search Engine Optimization",
    "CATEGORY": "Marketing",
    "COLOR": "#86db18",
    "FONT_FAMILY": "fantasy",
    "TEXT_TRANSFORM": "lowercase",
    "FONT_STYLE": "inherit",
    "FONT_WEIGHT": "inherit"
  },
  {
    "ITEM_ID": 517,
    "TITLE": "Powerful Social Media Marketing For Beginners",
    "SUBCATEGORY": "Social Media Marketing",
    "CATEGORY": "Marketing",
    "COLOR": "#4ec94c",
    "FONT_FAMILY": "cursive",
    "TEXT_TRANSFORM": "uppercase",
    "FONT_STYLE": "italic",
    "FONT_WEIGHT": "inherit"
  },
  {
    "ITEM_ID": 518,
    "TITLE": "Online Reputation Management: Negative Business Reviews",
    "SUBCATEGORY": "Social Media Marketing",
    "CATEGORY": "Marketing",
    "COLOR": "#d0ed6a",
    "FONT_FAMILY": "cursive",
    "TEXT_TRANSFORM": "inherit",
    "FONT_STYLE": "inherit",
    "FONT_WEIGHT": "bold"
  },
  {
    "ITEM_ID": 519,
    "TITLE": "How to VLOG: Complete Beginner’s Course",
    "SUBCATEGORY": "Video & Mobile Marketing",
    "CATEGORY": "Marketing",
    "COLOR": "#a7f9ac",
    "FONT_FAMILY": "serif",
    "TEXT_TRANSFORM": "uppercase",
    "FONT_STYLE": "inherit",
    "FONT_WEIGHT": "inherit"
  },
  {
    "ITEM_ID": 520,
    "TITLE": "LIVE Streaming Masterclass: Start Live Streaming like a Pro",
    "SUBCATEGORY": "Video & Mobile Marketing",
    "CATEGORY": "Marketing",
    "COLOR": "#41dba5",
    "FONT_FAMILY": "serif",
    "TEXT_TRANSFORM": "uppercase",
    "FONT_STYLE": "italic",
    "FONT_WEIGHT": "inherit"
  },
  {
    "ITEM_ID": 601,
    "TITLE": "The Secrets to Drawing",
    "SUBCATEGORY": "Arts & Crafts",
    "CATEGORY": "Lifestyle",
    "COLOR": "#bc7427",
    "FONT_FAMILY": "serif",
    "TEXT_TRANSFORM": "uppercase",
    "FONT_STYLE": "inherit",
    "FONT_WEIGHT": "inherit"
  },
  {
    "ITEM_ID": 602,
    "TITLE": "Learn to Paint With Impact",
    "SUBCATEGORY": "Arts & Crafts",
    "CATEGORY": "Lifestyle",
    "COLOR": "#c15105",
    "FONT_FAMILY": "cursive",
    "TEXT_TRANSFORM": "lowercase",
    "FONT_STYLE": "italic",
    "FONT_WEIGHT": "inherit"
  },
  {
    "ITEM_ID": 603,
    "TITLE": "The Watercolor Workshop",
    "SUBCATEGORY": "Arts & Crafts",
    "CATEGORY": "Lifestyle",
    "COLOR": "#edb97b",
    "FONT_FAMILY": "monospace",
    "TEXT_TRANSFORM": "uppercase",
    "FONT_STYLE": "italic",
    "FONT_WEIGHT": "bold"
  },
  {
    "ITEM_ID": 604,
    "TITLE": "Sewing 101",
    "SUBCATEGORY": "Arts & Crafts",
    "CATEGORY": "Lifestyle",
    "COLOR": "#f48c1d",
    "FONT_FAMILY": "monospace",
    "TEXT_TRANSFORM": "inherit",
    "FONT_STYLE": "inherit",
    "FONT_WEIGHT": "bold"
  },
  {
    "ITEM_ID": 605,
    "TITLE": "The Pen and Ink Experience",
    "SUBCATEGORY": "Arts & Crafts",
    "CATEGORY": "Lifestyle",
    "COLOR": "#c19e3c",
    "FONT_FAMILY": "serif",
    "TEXT_TRANSFORM": "uppercase",
    "FONT_STYLE": "inherit",
    "FONT_WEIGHT": "bold"
  },
  {
    "ITEM_ID": 611,
    "TITLE": "Healthy Cooking Fundamentals",
    "SUBCATEGORY": "Food & Beverage",
    "CATEGORY": "Lifestyle",
    "COLOR": "#f7986c",
    "FONT_FAMILY": "cursive",
    "TEXT_TRANSFORM": "lowercase",
    "FONT_STYLE": "inherit",
    "FONT_WEIGHT": "bold"
  },
  {
    "ITEM_ID": 612,
    "TITLE": "Sourdough Bread Baking 101",
    "SUBCATEGORY": "Food & Beverage",
    "CATEGORY": "Lifestyle",
    "COLOR": "#db8802",
    "FONT_FAMILY": "monospace",
    "TEXT_TRANSFORM": "inherit",
    "FONT_STYLE": "inherit",
    "FONT_WEIGHT": "inherit"
  },
  {
    "ITEM_ID": 613,
    "TITLE": "Essential Cooking Skills",
    "SUBCATEGORY": "Food & Beverage",
    "CATEGORY": "Lifestyle",
    "COLOR": "#c66e09",
    "FONT_FAMILY": "monospace",
    "TEXT_TRANSFORM": "uppercase",
    "FONT_STYLE": "inherit",
    "FONT_WEIGHT": "bold"
  },
  {
    "ITEM_ID": 614,
    "TITLE": "Get Wine-Smart",
    "SUBCATEGORY": "Food & Beverage",
    "CATEGORY": "Lifestyle",
    "COLOR": "#f4d48d",
    "FONT_FAMILY": "serif",
    "TEXT_TRANSFORM": "lowercase",
    "FONT_STYLE": "italic",
    "FONT_WEIGHT": "inherit"
  },
  {
    "ITEM_ID": 615,
    "TITLE": "The Most Comprehensive Sushi Course Online",
    "SUBCATEGORY": "Food & Beverage",
    "CATEGORY": "Lifestyle",
    "COLOR": "#ffebcc",
    "FONT_FAMILY": "monospace",
    "TEXT_TRANSFORM": "inherit",
    "FONT_STYLE": "inherit",
    "FONT_WEIGHT": "inherit"
  },
  {
    "ITEM_ID": 621,
    "TITLE": "Counter-Strike: Global Offensive — Play Like a Pro",
    "SUBCATEGORY": "Gaming",
    "CATEGORY": "Lifestyle",
    "COLOR": "#e2b258",
    "FONT_FAMILY": "monospace",
    "TEXT_TRANSFORM": "lowercase",
    "FONT_STYLE": "italic",
    "FONT_WEIGHT": "bold"
  },
  {
    "ITEM_ID": 622,
    "TITLE": "The Complete Guide to League of Legends",
    "SUBCATEGORY": "Gaming",
    "CATEGORY": "Lifestyle",
    "COLOR": "#ffe9ba",
    "FONT_FAMILY": "fantasy",
    "TEXT_TRANSFORM": "inherit",
    "FONT_STYLE": "inherit",
    "FONT_WEIGHT": "bold"
  },
  {
    "ITEM_ID": 623,
    "TITLE": "Chess Tactics Essentials",
    "SUBCATEGORY": "Gaming",
    "CATEGORY": "Lifestyle",
    "COLOR": "#ef912d",
    "FONT_FAMILY": "serif",
    "TEXT_TRANSFORM": "inherit",
    "FONT_STYLE": "italic",
    "FONT_WEIGHT": "inherit"
  },
  {
    "ITEM_ID": 624,
    "TITLE": "Essential Poker Math for No Limit Holdem",
    "SUBCATEGORY": "Gaming",
    "CATEGORY": "Lifestyle",
    "COLOR": "#fcf0c7",
    "FONT_FAMILY": "serif",
    "TEXT_TRANSFORM": "inherit",
    "FONT_STYLE": "italic",
    "FONT_WEIGHT": "bold"
  },
  {
    "ITEM_ID": 625,
    "TITLE": "The Complete Guide to Twitch Streaming",
    "SUBCATEGORY": "Gaming",
    "CATEGORY": "Lifestyle",
    "COLOR": "#ceac48",
    "FONT_FAMILY": "cursive",
    "TEXT_TRANSFORM": "uppercase",
    "FONT_STYLE": "inherit",
    "FONT_WEIGHT": "inherit"
  },
  {
    "ITEM_ID": 631,
    "TITLE": "Patching Walls Like a Pro",
    "SUBCATEGORY": "Home Improvement",
    "CATEGORY": "Lifestyle",
    "COLOR": "#e08a5c",
    "FONT_FAMILY": "serif",
    "TEXT_TRANSFORM": "uppercase",
    "FONT_STYLE": "italic",
    "FONT_WEIGHT": "bold"
  },
  {
    "ITEM_ID": 632,
    "TITLE": "Home Repair Made Easy",
    "SUBCATEGORY": "Home Improvement",
    "CATEGORY": "Lifestyle",
    "COLOR": "#e08f3e",
    "FONT_FAMILY": "fantasy",
    "TEXT_TRANSFORM": "lowercase",
    "FONT_STYLE": "inherit",
    "FONT_WEIGHT": "inherit"
  },
  {
    "ITEM_ID": 633,
    "TITLE": "DIY Home Inspection",
    "SUBCATEGORY": "Home Improvement",
    "CATEGORY": "Lifestyle",
    "COLOR": "#ea9662",
    "FONT_FAMILY": "serif",
    "TEXT_TRANSFORM": "inherit",
    "FONT_STYLE": "inherit",
    "FONT_WEIGHT": "inherit"
  },
  {
    "ITEM_ID": 634,
    "TITLE": "The Basics of Household Wiring",
    "SUBCATEGORY": "Home Improvement",
    "CATEGORY": "Lifestyle",
    "COLOR": "#ce7b29",
    "FONT_FAMILY": "serif",
    "TEXT_TRANSFORM": "inherit",
    "FONT_STYLE": "inherit",
    "FONT_WEIGHT": "inherit"
  },
  {
    "ITEM_ID": 635,
    "TITLE": "The Electrical System A to Z",
    "SUBCATEGORY": "Home Improvement",
    "CATEGORY": "Lifestyle",
    "COLOR": "#f2bf98",
    "FONT_FAMILY": "serif",
    "TEXT_TRANSFORM": "uppercase",
    "FONT_STYLE": "italic",
    "FONT_WEIGHT": "bold"
  },
  {
    "ITEM_ID": 641,
    "TITLE": "The Beginner's Guide to Vegetable Gardening",
    "SUBCATEGORY": "Gardening",
    "CATEGORY": "Lifestyle",
    "COLOR": "#d66a06",
    "FONT_FAMILY": "monospace",
    "TEXT_TRANSFORM": "lowercase",
    "FONT_STYLE": "inherit",
    "FONT_WEIGHT": "bold"
  },
  {
    "ITEM_ID": 642,
    "TITLE": "Online Bonsai Course",
    "SUBCATEGORY": "Gardening",
    "CATEGORY": "Lifestyle",
    "COLOR": "#fcc374",
    "FONT_FAMILY": "fantasy",
    "TEXT_TRANSFORM": "uppercase",
    "FONT_STYLE": "italic",
    "FONT_WEIGHT": "bold"
  },
  {
    "ITEM_ID": 643,
    "TITLE": "Growing Fruit Trees with Ease",
    "SUBCATEGORY": "Gardening",
    "CATEGORY": "Lifestyle",
    "COLOR": "#f9a27a",
    "FONT_FAMILY": "cursive",
    "TEXT_TRANSFORM": "uppercase",
    "FONT_STYLE": "italic",
    "FONT_WEIGHT": "bold"
  },
  {
    "ITEM_ID": 644,
    "TITLE": "Outdoor Gardening",
    "SUBCATEGORY": "Gardening",
    "CATEGORY": "Lifestyle",
    "COLOR": "#e2af73",
    "FONT_FAMILY": "serif",
    "TEXT_TRANSFORM": "lowercase",
    "FONT_STYLE": "inherit",
    "FONT_WEIGHT": "bold"
  },
  {
    "ITEM_ID": 645,
    "TITLE": "Ultimate Beginner's Guide to Gardening in Cold Climates",
    "SUBCATEGORY": "Gardening",
    "CATEGORY": "Lifestyle",
    "COLOR": "#f48230",
    "FONT_FAMILY": "cursive",
    "TEXT_TRANSFORM": "inherit",
    "FONT_STYLE": "italic",
    "FONT_WEIGHT": "bold"
  }
]