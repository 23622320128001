import { createSlice } from "@reduxjs/toolkit";

export const myCoursesSlice = createSlice({
  name: "myCourses",
  initialState: {
    addedCourses: JSON.parse(sessionStorage.getItem('addedCourses') || '{}'),
    finishedCourses: JSON.parse(sessionStorage.getItem('finishedCourses') || '{}')
  },
  reducers: {
    addCourse: (state, action) => {
      state.addedCourses[action.payload] = true;
      sessionStorage.setItem('addedCourses', JSON.stringify(state.addedCourses));
    },
    finishCourse: (state, action) => {
      state.finishedCourses[action.payload] = true;
      sessionStorage.setItem('finishedCourses', JSON.stringify(state.finishedCourses));
    },
  },
});

export const { addCourse, finishCourse } = myCoursesSlice.actions;

export const selectIsCourseAdded = (state) => {
  return (courseId) => {
      return state.myCourses.addedCourses[courseId] || false
  };
};
export const selectIsCourseFinished = (state) => {
  return (courseId) => {
      return state.myCourses.finishedCourses[courseId] || false
  };
};

export default myCoursesSlice.reducer;
